import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'

const FAQ = () => {
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle="FAQ" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="FAQ" 
            />

            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion allowZeroExpanded preExpanded={['a']}>
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. What is Occupational Therapy?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Occupational therapy focuses on the activities of daily living such as dressing, grooming, eating, and homemaking.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. What is the difference between Occupational and Physical Therapy?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    When trying to understand the differences between Physical Therapy (PT) and occupational therapy (OT), you must first understand that they both fall under the physical rehabilitation umbrella. The main difference between the two is that PT focuses on the recovery and improvement of movements that your body performs while OT focuses on the improvement of activities that you commonly perform throughout the day, referred to as activities of daily living (ADL). Both PT and OT can be incorporated after an injury or illness, but OT more commonly helps people with behavioral, emotional, and/or motor skill disabilities.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. What should I have ready for my Occupational Therapy appointment?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Any information regarding your medical condition will be useful when assessing your needs and determining goals for treatment. If you have test results, medical reports and/or medication on had it will help in targeting the services that you need.</p>

                                    <p>If you are undergoing lymphoedema treatment, please bring any previously worn or prescribed garments with you to the appointment.</p>

                                    <p>If you are undergoing hands on treatment, you may feel more comfortable wearing shorts rather than long pants or jeans which would need to be removed for assessment and treatment.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="d">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Can I claim through Medicare?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>We are a registered Medicare provider. Medicare provides claim benefits for chronic medical conditions and for clients with complex care needs. If you are living with a condition that has lasted, or is predicted to last, for six months or longer, you may be eligible for Medicare assistance under the Chronic Disease Management (CDM) scheme. Chronic conditions covered include cancer, lymphoedema, arthritis, heart disease and diabetes. Eligible patients are entitled to Medicare benefits for up to 5 allied health consults (including occupational therapy) per calender year.</p>
                                    <p>To be eligible for Medicare benefits, you will require a referral for Occupational Therapy services from your General Practitioner and our services must be part of an overall care plan. A gap payment may also be required. After your first and fifth consult, we will provide written feedback to your GP to ensure that your care plan is being followed.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="e">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. Can I claim through my private health care fund?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>We provide occupational therapy services on a fee for service basis to any private client, regardless of whether you have private health cover.</p>
                                    <p>If you have extras cover, you may be eligible for a rebate through your private health fund.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>

            
            
            <Footer />
        </Layout>
    );
}

export default FAQ